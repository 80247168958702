exports.components = {
  "component---src-pages-400-tsx": () => import("./../../../src/pages/400.tsx" /* webpackChunkName: "component---src-pages-400-tsx" */),
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-form-submit-tsx": () => import("./../../../src/pages/form-submit.tsx" /* webpackChunkName: "component---src-pages-form-submit-tsx" */),
  "component---src-pages-genographic-tsx": () => import("./../../../src/pages/genographic.tsx" /* webpackChunkName: "component---src-pages-genographic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-smooth-scroll-tsx": () => import("./../../../src/pages/smooth-scroll.tsx" /* webpackChunkName: "component---src-pages-smooth-scroll-tsx" */)
}

